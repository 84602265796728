const tooltips = {
  title: { title: 'Campo de texto para insertar el Título de la Notificación', active: false },
  shortMessage: { title: 'Campo de texto para insertar el Mensaje Corto de la Notificación', active: false },
  largeMessage: { title: 'Campo de texto para insertar el Mensaje Largo de la Notificación', active: false },
  link: { title: 'Campo de texto insertar el link de la notificación', active: false },
  nameLink: { title: 'Campo de texto insertar el nombre con que aparecerá el link', active: false },
  publicationDate: { title: 'Campo de fecha para insertar la Fecha de Envío de la Notificación', active: false },
  minAge: { title: 'Campo numérico para insertar la edad mínima que será notificada', active: false },
  maxAge: { title: 'Campo numérico para insertar la edad máxima que será notificada', active: false },
  addressee: { title: 'Campo numérico que muestra la cantidad de destinatarios alcanzados en las notificaciones', active: false },
  allAges: { title: 'Campo check para marcar si desea que dicha notificación sea para todas las edades o para un rango de edad', active: false },
  civilStatus: { title: 'Campo selector multiple para insertar los Estados Civiles a los cuales se le notificará', active: false },
  sector: { title: 'Campo selector para insertar los sectores que serán notificados', active: false },
  gender: { title: 'Campo selector para insertar el género al cual será notificado', active: false },
}

export default tooltips
