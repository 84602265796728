<template>
  <v-dialog
    v-model="dialog_log"
    transition="dialog-bottom-transition"
    scrollable
    max-width="70%"
    persistent
  >
    <v-form
      ref="form"
      class="multi-col-validation"
      lazy-validation
    >
      <v-card>
        <v-card-title>{{ formTitulo }}</v-card-title>
        <v-row class="mx-5">
          <!-- Nombre -->
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="refName"
              v-model="obj.name"
              label="Nombre"
              outlined
              dense
              placeholder="Nombre"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="refusername"
              v-model="obj.userName"
              label="Usuario"
              outlined
              dense
              placeholder="Usuario"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="refoperetionType"
              v-model="obj.operationType"
              label="Tipo de operación"
              outlined
              dense
              placeholder="Tipo de operación"
            >
            </v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              ref="refcreatedAt"
              v-model="obj.createdAt"
              label="Fecha de la operación"
              outlined
              dense
              placeholder="Fecha de la operación"
            >
            </v-text-field>
          </v-col>
          <v-col
            v-if="false"
            cols="12"
          >
            <v-textarea
              ref="refinitialValue"
              v-model="obj.initialValue"
              label="Valor inicial"
              outlined
              dense
              placeholder="Valor inicial"
            >
            </v-textarea>
          </v-col>
          <v-col
            v-if="false"
            cols="12"
          >
            <v-textarea
              ref="refinitialValue"
              v-model="obj.finalValue"
              label="Valor final"
              outlined
              dense
              placeholder="Valor final"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-card-actions
          v-if="true"
          class="mt-5"
        >
          <v-row>
            <!-- Botones de acción -->
            <v-col cols="12">
              <v-btn
                type="reset"
                outlined
                class="mx-2"
                @click="$emit('close_dialog')"
              >
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { reactive, ref } from '@vue/composition-api'
import HTTP from '@/utils/axios/axios-config-base'

export default {
  props: {
    dialog_log: {
      type: Boolean,
      require: true,
      default: '',
    },
    id: {
      type: String,
      require: true,
      default: '',
    },
  },
  watch: {
    dialog_log(val) {
      if (val) {
        this.detalleLogsById(this.id)
      }
    },
  },
  setup() {
    const formTitulo = 'Detalles del log'
    const obj = reactive({
      createAt: '',
      entityName: '',
      finalValue: '',
      initialValue: '',
      name: '',
      operationType: '',
      systemLogId: '',
      userName: '',
    })
    const detalleLogsById = async id => {
      console.log(id)
      obj.createdAt = ''
      obj.entityName = ''
      obj.finalValue = ''
      obj.initialValue = ''
      obj.name = ''
      obj.operationType = ''
      obj.systemLogId = ''
      obj.userName = ''
      try {
        if (id) {
          const URL = `${HTTP.defaults.baseURL}/identity-api/systemLogs/${id}`
          const resp = await HTTP.get(URL)
          obj.createdAt = resp.data.value.createdAt
          obj.entityName = resp.data.value.entityName
          obj.finalValue = resp.data.value.finalValue
          obj.initialValue = resp.data.value.initialValue
          obj.name = resp.data.value.name
          obj.operationType = resp.data.value.operationType
          obj.systemLogId = resp.data.value.systemLogId
          obj.userName = resp.data.value.userName
        }
      } catch (error) {}
    }

    return {
      formTitulo,
      obj,
      detalleLogsById,
    }
  },
}
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
.border {
  border: #e0e0e0 solid 1px;
  border-radius: 15px;
}

.border-tramite-activo {
  border: #185faa solid 2px;
}
.border-tramite {
  border: white solid 2px;
}
.border-tramite:hover {
  border: #185faa solid 2px;
}

.border-transiciones-activo {
  border: #4caf50 solid 2px;
}
.border-transiciones {
  border: white solid 2px;
}
.border-transiciones:hover {
  border: #4caf50 solid 2px;
}

.border-estado-activo {
  border: #fbad4b solid 2px;
}
.border-estado {
  border: white solid 2px;
}
.border-estado:hover {
  border: #fbad4b solid 2px;
}

.compact-form {
  transform: scale(0.7);
  transform-origin: left;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
  /* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.truncate_name {
    max-width: 12vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate {
    max-width: 30vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
