<template>
  <v-row class="match-height">
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12">
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ formTitulo }}</v-card-title>
        <v-tabs
          v-model="tab"
          show-arrows
          class="mb-10"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.id"
            @click="keyComponnet += 1"
          >
            <v-icon
              v-if="tab.error"
              color="error"
            >
              {{ tab.icons }}
            </v-icon>
            <span class="mx-2">{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <!-- tabs item -->
        <v-tabs-items v-model="tab">
          <v-tab-item eager>
            <v-card-text>
              <v-form
                id="form"
                ref="form"
                class="multi-col-validation mt-1"
                :loading="loadingSaveForm"
                lazy-validation
                :disabled="formdisabled"
              >
                <v-row>
                  <!-- Imagen -->
                  <v-col
                    cols="12"
                    md="4"
                    class="d-flex justify-center align-center"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        max-width="300"
                        max-height="300"
                        height="300"
                        width="300"
                        class="mx-auto"
                        :disabled="formdisabled"
                      >
                        <v-container
                          class="pa-1"
                          style="cursor:pointer;"
                        >
                          <v-img
                            v-if="isUrl(obj.image)"
                            :src="obj.image"
                            height="300"
                            class="text-right"
                            @error="offLink = true"
                          >
                            <template #placeholder>
                              <v-row
                                class="fill-height ma-0 grey"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  v-if="offLink === false"
                                  size="70"
                                  indeterminate
                                  color="grey lighten-5"
                                >
                                </v-progress-circular>
                                <v-icon
                                  v-else
                                  size="50"
                                  dark
                                >
                                  {{ iconoLink }}
                                </v-icon>
                              </v-row>
                            </template>
                            <v-expand-transition>
                              <div
                                v-if="hover && isEditing"
                                class="
                                  d-flex
                                  transition-fast-in-fast-out
                                  primary
                                  darken-2
                                  v-card--reveal
                                  white--text
                                "
                                style="height: 100%"
                                @click="onPickFile"
                              >
                                <div>
                                  <div
                                    class="
                                      d-flex
                                      justify-center
                                      white--text
                                      display-1
                                    "
                                  >
                                    Click
                                  </div>
                                </div>
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <v-img
                            v-else
                            :src="defaultImage"
                            max-width="300"
                            max-height="300"
                            height="300"
                            width="300"
                            class="text-right"
                            @error="offLink = true"
                          >
                            <template #placeholder>
                              <v-row
                                class="fill-height ma-0 grey"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  v-if="offLink === false"
                                  size="70"
                                  indeterminate
                                  color="grey lighten-5"
                                >
                                </v-progress-circular>
                                <v-icon
                                  v-else
                                  size="50"
                                  dark
                                >
                                  {{ iconoLink }}
                                </v-icon>
                              </v-row>
                            </template>
                            <v-expand-transition>
                              <div
                                v-if="hover && isEditing"
                                class="
                                  d-flex
                                  transition-fast-in-fast-out
                                  primary
                                  darken-2
                                  v-card--reveal
                                  white--text
                                "
                                style="height: 100%"
                                @click="onPickFile"
                              >
                                <div>
                                  <div
                                    class="
                                      d-flex
                                      justify-center
                                      white--text
                                      display-1
                                    "
                                  >
                                    Click
                                  </div>
                                </div>
                              </div>
                            </v-expand-transition>
                          </v-img>
                          <input
                            id="fileInput"
                            ref="fileInput"
                            type="file"
                            accept="image/*"
                            style="display: none"
                            :disabled="!isEditing"
                            @change="onFilePicked"
                          />
                        </v-container>
                      </v-card>
                    </v-hover>
                  </v-col>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-row>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="obj.title"
                          label="Título"
                          outlined
                          dense
                          placeholder="Título"
                          :rules="[
                            (v) => !!v || 'El campo título es obligatorio',
                            (v) => (!!v && v.length <= 40) || 'El campo título admite hasta 40 caracteres',
                          ]"
                          counter="40"
                          maxlength="40"
                          required
                          error-count="2"
                          :error="campos.title.error"
                          :error-messages="errorMsgTitle"
                        >
                          <template
                            v-if="tooltips.title.active"
                            v-slot:append
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                  {{ icons.mdiAlertCircleOutline }}
                                </v-icon>
                              </template>
                              {{ tooltips.title.title }}
                            </v-tooltip>
                          </template>
                          <template #label>
                            <app-label
                              field="Título"
                              required="true"
                            />
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-menu
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          max-width="290px"
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              persistent-hint
                              :prepend-inner-icon="icons.mdiCalendar"
                              placeholder="Fecha de Envío"
                              outlined
                              dense
                              readonly
                              v-bind="attrs"
                              :rules="[
                                (v) => !!v || 'El campo Fecha de Envío es obligatorio',
                              ]"
                              :value="formatDate"
                              v-on="on"
                            >
                              <template
                                v-if="tooltips.publicationDate.active"
                                v-slot:append
                              >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon v-on="on">
                                      {{ icons.mdiAlertCircleOutline }}
                                    </v-icon>
                                  </template>
                                  {{ tooltips.publicationDate.title }}
                                </v-tooltip>
                              </template>
                              <template #label>
                                <app-label
                                  field="Fecha de Envío"
                                  required="true"
                                />
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="obj.publicationDate"
                            no-title
                            :min="minDate"
                            @input="menu = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          v-model="obj.shortMessage"
                          label="Mensaje Corto"
                          outlined
                          dense
                          placeholder="Mensaje Corto"
                          :rules="[
                            (v) => !!v || 'El campo Mensaje Corto es obligatorio',
                            (v) => (!!v && v.length <= 178) || 'El campo Mensaje Corto admite hasta 178 caracteres',
                          ]"
                          counter="178"
                          maxlength="178"
                          required
                          error-count="2"
                          :error="campos.shortMessage.error"
                          :error-messages="errorMsgShortMessage"
                        >
                          <template
                            v-if="tooltips.shortMessage.active"
                            v-slot:append
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                  {{ icons.mdiAlertCircleOutline }}
                                </v-icon>
                              </template>
                              {{ tooltips.shortMessage.title }}
                            </v-tooltip>
                          </template>
                          <template #label>
                            <app-label
                              field="Mensaje Corto"
                              required="true"
                            />
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          v-model="obj.largeMessage"
                          outlined
                          dense
                          placeholder="Mensaje Largo"
                          error-count="2"
                          :error="campos.largeMessage.error"
                          :error-messages="errorMsgLargeMessage"
                          :rules="[
                            (v) => !!v || 'El campo Mensaje Largo es obligatorio',
                            (v) => (!!v && v.length <= 250) || 'El campo Mensaje Largo admite hasta 250 caracteres',
                          ]"
                          counter="250"
                          maxlength="250"
                        >
                          <template
                            v-if="tooltips.largeMessage.active"
                            v-slot:append
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                  {{ icons.mdiAlertCircleOutline }}
                                </v-icon>
                              </template>
                              {{ tooltips.largeMessage.title }}
                            </v-tooltip>
                          </template>
                          <template #label>
                            <app-label
                              field="Mensaje Largo"
                              required="true"
                            />
                          </template>
                        </v-textarea>
                      </v-col>
                      <!-- URL -->
                      <v-col
                        cols="12"
                        md="8"
                      >
                        <v-text-field
                          v-model="obj.link"
                          label="Url"
                          outlined
                          dense
                          placeholder="Url"
                          :rules="[
                            (v) => (v.length === 0 || /^(ftp|http|https|chrome|:\/\/|\.|@){2,}(localhost|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\S*:\w*@)*([a-zA-Z]|(\d{1,3}|\.){7}){1,}(\w|\.{2,}|\.[a-zA-Z]{2,3}|\/|\?|&|:\d|@|=|\/|\(.*\)|#|-|%)*$/gum.test(v)) || 'El campo Url no es válida',
                          ]"
                          counter="250"
                          maxlength="250"
                          error-count="2"
                          :error="campos.link.error"
                          :error-messages="errorMsgLink"
                        >
                          <template
                            v-if="tooltips.link.active"
                            v-slot:append
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                  {{ icons.mdiAlertCircleOutline }}
                                </v-icon>
                              </template>
                              {{ tooltips.link.title }}
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- Name URL -->
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <v-text-field
                          v-model="obj.nameLink"
                          label="Nombre de la URL"
                          outlined
                          dense
                          placeholder="Nombre de la URL"
                          counter="100"
                          maxlength="100"
                          error-count="2"
                          :error="campos.nameLink.error"
                          :error-messages="errorMsgnameLink"
                        >
                          <template
                            v-if="tooltips.nameLink.active"
                            v-slot:append
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-icon v-on="on">
                                  {{ icons.mdiAlertCircleOutline }}
                                </v-icon>
                              </template>
                              {{ tooltips.nameLink.title }}
                            </v-tooltip>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <!-- Estado -->
                  <v-col
                    v-if="true"
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="obj.status"
                      :items="listaStatus"
                      item-value="id"
                      item-text="name"
                      label="Estado"
                      placeholder="Estado"
                      outlined
                      dense
                      :rules="[
                        (v) => !!v || 'El campo Estado es obligatorio',
                      ]"
                      required
                      clearable
                      error-count="2"
                      :error="campos.status.error"
                      :error-messages="errorMsgStatus"
                    ></v-select>
                  </v-col>

                  <!-- Estado civil -->
                  <v-col
                    v-if="true"
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="obj.civilStatus"
                      :items="civilStatus"
                      item-value="id"
                      item-text="name"
                      label="Estado civil"
                      placeholder="Estado civil"
                      multiple
                      outlined
                      dense
                      required
                      clearable
                      error-count="2"
                      :error="campos.status.error"
                      :error-messages="errorMsgStatus"
                      @change="onChangeCivilStatus(obj.civilStatus)"
                      @blur="onBlurCivilStatus(obj.civilStatus)"
                    >
                      <template
                        v-if="tooltips.civilStatus.active"
                        v-slot:append
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              {{ icons.mdiAlertCircleOutline }}
                            </v-icon>
                          </template>
                          {{ tooltips.civilStatus.title }}
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>
                  <!-- Genero -->
                  <v-col
                    v-if="true"
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="obj.gender"
                      :items="gender"
                      item-value="id"
                      item-text="name"
                      label="Género"
                      placeholder="Género"
                      outlined
                      dense
                      clearable
                      @change="getCantidadDestinatarios"
                      @blur="onBlurGenero(obj.gender)"
                    >
                      <template
                        v-if="tooltips.gender.active"
                        v-slot:append
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              {{ icons.mdiAlertCircleOutline }}
                            </v-icon>
                          </template>
                          {{ tooltips.gender.title }}
                        </v-tooltip>
                      </template>
                    </v-select>
                  </v-col>
                  <!-- Sector -->
                  <v-col
                    v-if="true"
                    cols="12"
                    md="12"
                  >
                    <v-combobox
                      v-model="obj.sector"
                      :items="listSector"
                      item-value="SECT_NOMEN"
                      item-text="SECT_NOMEN"
                      label="Sector"
                      placeholder="Sector"
                      outlined
                      multiple
                      chips
                      small-chips
                      deletable-chips
                      dense
                      required
                      error-count="2"
                      clearable
                      :loading="loadingSector"
                      :error="campos.status.error"
                      :error-messages="errorMsgStatus"
                      @change="onChangeSector(obj.sector)"
                      @blur="onBlurSector(obj.sector)"
                    >
                      <template
                        v-if="tooltips.sector.active"
                        v-slot:append
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              {{ icons.mdiAlertCircleOutline }}
                            </v-icon>
                          </template>
                          {{ tooltips.sector }}
                        </v-tooltip>
                      </template>
                    </v-combobox>
                  </v-col>

                  <!-- Check Todas las Edades-->
                  <v-col
                    v-if="true"
                    cols="12"
                    md="2"
                    class="my-0 py-0"
                  >
                    <v-checkbox
                      v-model="obj.allAges"
                      label="Todas las edades"
                      @change="changeTodasEdades"
                    >
                      <template
                        v-if="tooltips.allAges.active"
                        v-slot:prepend
                      >
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              {{ icons.mdiAlertCircleOutline }}
                            </v-icon>
                          </template>
                          {{ tooltips.allAges.title }}
                        </v-tooltip>
                      </template>
                    </v-checkbox>
                  </v-col>

                  <!-- Rango de Edad -->
                  <v-col
                    v-if="true"
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      ref="refMinAge"
                      v-model="obj.ageRangeMin"
                      label="Edad (Min)"
                      class="mt-0 pt-0"
                      type="number"
                      outlined
                      dense
                      :min="12"
                      :max="obj.ageRangeMax"
                      :disabled="valueAllAges"
                      :rules="[
                        (v) => (!!v && v >= 12) || 'La edad mínima no puede ser menor que 12 años',
                        (v) => (!!v && v <= 120) || 'La edad mínima no puede ser mayor que 120 años',
                        (v) => (!!v && v <= obj.ageRangeMax) || 'La edad mínima no puede ser mayor que la edad máxima',
                      ]"
                      @change="getCantidadDestinatarios(); onChangeEdadMin()"
                    >
                      <template
                        v-if="tooltips.minAge.active"
                        v-slot:append
                      >
                        <v-tooltip right>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              {{ icons.mdiAlertCircleOutline }}
                            </v-icon>
                          </template>
                          {{ tooltips.minAge.title }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="true"
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      ref="refMaxAge"
                      v-model="obj.ageRangeMax"
                      label="Edad (Max)"
                      class="mt-0 pt-0"
                      outlined
                      dense
                      type="number"
                      :min:="obj.ageRangeMin"
                      :disabled="valueAllAges"
                      :max:="120"
                      :rules="[
                        (v) => (!!v && v >= 12) || 'La edad máxima no puede ser menor que 12 años',
                        (v) => (!!v && v <= 120) || 'La edad máxima no puede ser mayor que 120 años',
                        (v) => (!!v && v >= obj.ageRangeMin) || 'La edad máxima no puede ser menor que la edad mínima',
                      ]"
                      @change="getCantidadDestinatarios(); onChangeEdadMax()"
                    >
                      <template
                        v-if="tooltips.maxAge.active"
                        v-slot:append
                      >
                        <v-tooltip left>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              {{ icons.mdiAlertCircleOutline }}
                            </v-icon>
                          </template>
                          {{ tooltips.maxAge.title }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!-- Cantidad de Destinatarios Alcanzado -->
                  <v-col
                    v-if="true"
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="obj.addressee"
                      label="Cantidad de Destinatarios Alcanzado"
                      outlined
                      dense
                      type="number"
                      readonly
                      placeholder="Cantidad de Destinatarios Alcanzado"
                    >
                      <template
                        v-if="tooltips.addressee.active"
                        v-slot:append
                      >
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on">
                              {{ icons.mdiAlertCircleOutline }}
                            </v-icon>
                          </template>
                          {{ tooltips.addressee.title }}
                        </v-tooltip>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-btn
                      color="primary"
                      :loading="loadingSaveForm"
                      :disabled="formdisabled"
                      @click="saveForm"
                    >
                      Enviar
                    </v-btn>
                    <v-btn
                      type="reset"
                      outlined
                      class="mx-2"
                      @click="cancelarForm"
                    >
                      Cancelar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-tab-item>
          <!-- Seectores -->
          <v-tab-item
            v-if="true"
            eager
          >
            <v-row>
              <v-col
                cols="12"
                class="pb-10 px-5"
              >
                <v-card elevation="0">
                  <iframe
                    :src="`https://geoportalcat.guayaquil.gob.ec/portal/apps/View/index.html?appid=1dca6ed833bb4fdd89040547d126813d`"
                    width="100%"
                    height="500"
                    frameborder="0"
                  >
                  </iframe>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <!-- Los del Sistema -->
          <v-tab-item eager>
            <v-row class="pa-3 mt-2">
              <v-col cols="12">
                <tablaComponent
                  ref="tabla"
                  :headers="headers"
                  :titulo-tabla="tituloTabla"
                  :url="url"
                  :cargando="cargando"
                  :search="search"
                  :have-pagination="havePagination"
                  :have-actions="true"
                  :have-detail="haveDetail"
                  :have-search="haveSearch"
                  @dialogSearch="cargarSearch()"
                  @refreshSearch="refreshSearch()"
                  @detailitem="detailItem($event)"
                >
                  <template v-slot:[`item.initialValue`]="{ item }">
                    <div class="truncate">
                      {{ item.initialValue }}
                    </div>
                  </template>
                  <template v-slot:[`item.finalValue`]="{ item }">
                    <div class="truncate">
                      {{ item.finalValue }}
                    </div>
                  </template>
                </tablaComponent>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-col>
    <detallelogs_component
      :id="id_log"
      :dialog_log="dialog_log"
      @close_dialog="close_dialog()"
    />
  </v-row>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import { mdiCalendar, mdiAlertOctagonOutline, mdiAlertCircleOutline } from '@mdi/js'
import moment from 'moment'
import axios from 'axios'
import { useRouter } from '@/utils'
// eslint-disable-next-line import/no-cycle
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import tablaComponent from '@/components/tablaComponent.vue'
import detallelogs_component from '@/views/systemlog/detallelogs_component.vue'
import tooltips from '@/utils/toolstips/notificaciones'

export default {
  components: {
    AppLabel, Breadcrumbs, tablaComponent, detallelogs_component,
  },
  computed: {
    formatDate() {
      return this.obj.publicationDate ? moment(this.obj.publicationDate).format('DD-MM-YYYY') : ''
    },
  },

  setup() {
    const { router, route } = useRouter()
    const formTitulo = ref('Editar notificación')
    const loadingSaveForm = ref(false)
    const keyComponnet = ref(0)
    const tab = ref(0)
    const tabs = reactive([
      {
        id: '1', title: 'Datos Generales', icons: mdiAlertOctagonOutline, error: false,
      },
      {
        id: '2', title: 'Sectores', icons: mdiAlertOctagonOutline, error: false,
      },
      {
        id: '3', title: 'Logs del Sistema', icons: mdiAlertOctagonOutline, error: false,
      },
    ])

    // const title = ref('')
    const image = ref('')
    const imageFile = ref('')
    const defaultImage = ref(require('@/assets/images/default-img.png'))
    const iconoLink = ref(require('@/assets/images/default-img.png'))
    const dateNow = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
    const fileInput = ref(null)
    const offLink = ref(false)
    const isEditing = ref(true)
    const erroresDialog = ref(null)
    const titleDialog = ref(null)
    const dialog = ref(null)
    const form = ref(null)
    const refMaxAge = ref(null)
    const refMinAge = ref(null)
    const formdisabled = ref(false)
    const notificationId = route.value.params.id
    const listSector = ref([])
    const loadingSector = ref(false)
    const ageRangeMin = ref(12)
    const ageRangeMax = ref(120)
    const range = reactive([])
    const keyRangeComponnent = ref(0)
    const obj = reactive({
      id: null,
      title: '',
      image: null,
      shortMessage: '',
      largeMessage: '',
      link: '',
      nameLink: '',
      publicationDate: '',
      location: '',
      status: '',
      civilStatus: [],
      allAges: true,
      ageRangeMin: 12,
      ageRangeMax: 120,
      sector: [{
        SECT_NOMEN: 'TODOS',
        Cuadrante: 'TODOS',
      }],
      addressee: 0,
    })
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Notificaciones',
        disabled: false,
        href: '/notificaciones',
      },
      {
        text: `${formTitulo.value}`,
        disabled: true,
        href: '/notificaciones/form',
      },
    ])
    const civilStatus = ref([
      { id: 'all', name: 'Todos' },
      { id: 'SOLTERO/A', name: 'Soltero(a)' },
      { id: 'DIVORCIADO/A', name: 'Divorciado(a)' },
      { id: 'CASADO/A', name: 'Casado(a)' },
      { id: 'U.DE HECHO', name: 'Unión Libre' },
      { id: 'VIUDO/A', name: 'Viudo(a)' },
    ])
    const listaStatus = ref([
      { id: 'approved', name: 'Aprobado', disabled: false },
      { id: 'sending', name: 'Enviado', disabled: false },
      { id: 'pending', name: 'Pendiente', disabled: false },
      { id: 'delete', name: 'Eliminado', disabled: true },
    ])
    const gender = ref([
      { id: 'MASCULINO', name: 'Masculino', disabled: false },
      { id: 'FEMENINO', name: 'Femenino', disabled: false },
      { id: 'all', name: 'Todos', disabled: false },
    ])
    const campos = reactive({
      title: {
        error: false,
        mensajesError: [],
      },
      image: {
        error: false,
        mensajesError: [],
      },
      shortMessage: {
        error: false,
        mensajesError: [],
      },
      largeMessage: {
        error: false,
        mensajesError: [],
      },
      link: {
        error: false,
        mensajesError: [],
      },
      nameLink: {
        error: false,
        mensajesError: [],
      },
      publicationDate: {
        error: false,
        mensajesError: [],
      },
      location: {
        error: false,
        mensajesError: [],
      },
      status: {
        error: false,
        mensajesError: [],
      },
      sector: {
        error: false,
        mensajesError: [],
      },
      civilStatus: {
        error: false,
        mensajesError: [],
      },
      ageRangeMin: {
        error: false,
        mensajesError: [],
      },
      ageRangeMax: {
        error: false,
        mensajesError: [],
      },
    })

    const errorMsgTitle = computed(() => (campos.title.error ? campos.title.mensajesError : []))
    const errorMsgImage = computed(() => (campos.image.error ? campos.image.mensajesError : []))
    const errorMsgShortMessage = computed(() => (campos.shortMessage.error ? campos.shortMessage.mensajesError : []))
    const errorMsgLargeMessage = computed(() => (campos.largeMessage.error ? campos.largeMessage.mensajesError : []))
    const errorMsgLink = computed(() => (campos.link.error ? campos.link.mensajesError : []))
    const errorMsgnameLink = computed(() => (campos.nameLink.error ? campos.nameLink.mensajesError : []))
    const errorMsgPublicationDate = computed(() => (campos.publicationDate.error ? campos.publicationDate.mensajesError : []))
    const errorMsgLocation = computed(() => (campos.location.error ? campos.location.mensajesError : []))
    const errorMsgStatus = computed(() => (campos.status.error ? campos.status.mensajesError : []))
    const errorMsgCivilStatus = computed(() => (campos.civilStatus.error ? campos.civilStatus.mensajesError : []))
    const errorMsgAgeRangeMin = computed(() => (campos.ageRangeMin.error ? campos.ageRangeMin.mensajesError : []))
    const errorMsgAgeRangeMax = computed(() => (campos.ageRangeMax.error ? campos.ageRangeMax.mensajesError : []))
    const errorMsgSector = computed(() => (campos.sector.error ? campos.sector.mensajesError : []))
    const permissions = localStorage.getItem('permissions')
    const deshabilitarForm = computed(() => permissions.includes('Notifications:Notifications:FullAccess') || permissions.includes('Notifications:Notifications:ApproveAccess'))
    const disabledFields = computed(() => {
      if ((obj.status === 'approved' && obj.publicationDate < dateNow) || obj.status === 'sending') {
        return true
      }

      return false
    })
    const onChangeEdadMin = () => {
      refMaxAge.value.validate()
    }

    const onChangeEdadMax = () => {
      refMinAge.value.validate()
    }
    const valueAllAges = computed(() => obj.allAges)
    const isUrl = s => {
      const regexp = /(ftp|http|https):\/\/(\w+:?\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/

      return regexp.test(s)
    }
    const getCantidadDestinatarios = async () => {
      let genderTemp = null
      if (obj.gender !== 'all') {
        genderTemp = obj.gender
      }
      let civilStatusTemp = []
      if (obj.civilStatus.length > 0) {
        if (obj.civilStatus.includes('all')) {
          civilStatusTemp = null
        } else {
          civilStatusTemp = obj.civilStatus
        }
      }

      let listSectorTemp = []
      let listSectorString = null
      if (obj.sector.length > 0) {
        for (let index = 0; index < obj.sector.length; index += 1) {
          const element = obj.sector[index]
          if (typeof element === 'object') {
            listSectorTemp = [...listSectorTemp, element.SECT_NOMEN]
          }
          if (typeof element === 'string') {
            listSectorTemp = [...listSectorTemp, element]
          }
        }
      }

      if (listSectorTemp.includes('TODOS')) {
        listSectorString = null
      } else {
        listSectorString = listSectorTemp.length > 0 ? listSectorTemp.toString() : null
      }

      const objToSave = {
        notificationType: 'notificacionsegment',
        category: null,
        gender: genderTemp || null,
        locations: listSectorString,
        maritalStatus: civilStatusTemp ? civilStatusTemp.toString() : null,
        ageInitial: obj.allAges ? null : obj.ageRangeMin,
        ageFinal: obj.allAges ? null : obj.ageRangeMax,
      }
      try {
        const URL = `${HTTP.defaults.baseURL}identity-api/users-data/count-mobiles`
        const resp = await HTTP.post(URL, objToSave)
        obj.addressee = 0
        obj.addressee = resp.data.devices
      } catch (error) {
        console.log(error)
      }
    }

    const onChangeSector = async idsSector => {
      for (let index = 0; index < idsSector.length; index += 1) {
        if (idsSector[index].SECT_NOMEN === 'TODOS') {
          obj.sector = []
          obj.sector = [...obj.sector, {
            SECT_NOMEN: 'TODOS',
            Cuadrante: 'TODOS',
          }]
        }
      }
      await getCantidadDestinatarios()
    }

    const onBlurSector = async idsSector => {
      if (idsSector.length === 0) {
        obj.sector = []
        obj.sector = [...obj.sector, {
          SECT_NOMEN: 'TODOS',
          Cuadrante: 'TODOS',
        }]
      }
      await getCantidadDestinatarios()
    }

    const onChangeCivilStatus = async idsCivilStatus => {
      if (idsCivilStatus.includes('all')) {
        obj.civilStatus = []
        for (let index = 0; index < civilStatus.value.length; index += 1) {
          if (civilStatus.value[index].id === 'all') {
            obj.civilStatus.push('all')
          }
        }
      }
      await getCantidadDestinatarios()
    }
    const onBlurCivilStatus = async idsCivilStatus => {
      if (idsCivilStatus.length === 0) {
        obj.civilStatus = []
        for (let index = 0; index < civilStatus.value.length; index += 1) {
          if (civilStatus.value[index].id === 'all') {
            obj.civilStatus.push('all')
          }
        }
      }
      await getCantidadDestinatarios()
    }
    const onBlurGenero = async idsGender => {
      if (!idsGender) {
        obj.gender = 'all'
      }
      await getCantidadDestinatarios()
    }

    const changeTodasEdades = async () => {
      obj.ageRangeMin = ageRangeMin.value
      obj.ageRangeMax = ageRangeMax.value
      await getCantidadDestinatarios()
    }

    const getSector = async () => {
      try {
        loadingSector.value = true
        const URL = 'https://geoportalcat.guayaquil.gob.ec/arcgis/rest/services/Geoportal_Actualizado/GEOPORTAL_ACTUALIZADO/MapServer/4/query?where=1=1&outFields=SECT_NOMEN,cuadrante&returnGeometry=false&f=pjson'
        const resp = await axios.get(URL)
        if (resp.status === 200) {
          listSector.value = []
          for (let i = 0; i < resp.data.features.length; i += 1) {
            listSector.value.push({
              ...resp.data.features[i].attributes,
            })
          }
          listSector.value = [...listSector.value, {
            SECT_NOMEN: 'TODOS',
            Cuadrante: 'TODOS',
          }]
        }
      } catch (error) {
        console.log(error)
      } finally {
        loadingSector.value = false
      }
    }
    const minDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)

    const getNotificationById = async id => {
      try {
        if (id) {
          const URL = `${HTTP.defaults.baseURL}/marketing-api/notifications/${id}`
          const resp = await HTTP.get(URL)
          if (resp.status === 200) {
            if (resp.data.value) {
              // obj.
              obj.id = resp.data.value.id
              obj.title = resp.data.value.title
              obj.image = resp.data.value.image === '' ? image : resp.data.value.image
              obj.shortMessage = resp.data.value.shortMessage
              obj.largeMessage = resp.data.value.largeMessage
              obj.link = resp.data.value.link
              obj.nameLink = resp.data.value.nameLink
              obj.publicationDate = moment(resp.data.value.publicationDate).format('YYYY-MM-DD')
              obj.location = resp.data.value.location
              obj.status = resp.data.value.status
              obj.gender = resp.data.value.gender || 'all'
              obj.civilStatus = resp.data.value.civilStatus ? resp.data.value.civilStatus.split(',') : ['all']
              obj.addressee = resp.data.value.addressee
              obj.ageRangeMin = resp.data.value.ageMin ? resp.data.value.ageMin : ageRangeMin.value
              obj.ageRangeMax = resp.data.value.ageMax ? resp.data.value.ageMax : ageRangeMax.value

              if (resp.data.value.ageMin || resp.data.value.ageMax) {
                obj.allAges = false
              }
              keyRangeComponnent.value += 1

              if (resp.data.value.status === 'sending' || resp.data.value.status === 'delete' || !deshabilitarForm.value) {
                formdisabled.value = true
              }
              if (resp.data.value.status === 'approved') {
                for (let index = 0; index < listaStatus.value.length; index += 1) {
                  if (listaStatus.value[index].id === 'delete') {
                    listaStatus.value[index].disabled = false
                  }
                  if (listaStatus.value[index].id === 'sending') {
                    listaStatus.value[index].disabled = true
                  }
                }
              }
              if (resp.data.value.status === 'pending') {
                for (let index = 0; index < listaStatus.value.length; index += 1) {
                  if (listaStatus.value[index].id === 'sending') {
                    listaStatus.value[index].disabled = true
                  }
                  if (listaStatus.value[index].id === 'delete') {
                    listaStatus.value[index].disabled = false
                  }
                }
              }
              await getSector()
              if (resp.data.value.sector) {
                obj.sector = []
                if (resp.data.value.sector.includes(',')) {
                  obj.sector = resp.data.value.sector.split(',')
                } else {
                  obj.sector = [...obj.sector, resp.data.value.sector]
                }
              }

              await getCantidadDestinatarios()
            }
          }
        }
      } catch (error) {
        console.log(error)
      }
    }

    // const onMessage = (title, message) => {
    //   titleDialog.value = title !== undefined ? title : 'Error'
    //   erroresDialog.value = message
    //   dialog.value = true
    // }

    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    getNotificationById(notificationId)
    getSector()

    // Logs del Sistema
    const tituloTabla = ref('Log del Sistema')
    const headers = ref([
      {
        text: 'Nombre', sortable: true, value: 'name', width: '200',
      },
      { text: 'Usuario', sortable: true, value: 'userName' },
      { text: 'Tipo de Operación', sortable: true, value: 'operationType' },
      { text: 'Fecha', sortable: true, value: 'createdAt' },
      {
        text: 'Acción', value: 'actions', align: 'center', width: '180', sortable: false,
      },

      /* {
        text: 'Valor Inicial', sortable: true, value: 'initialValue', width: 50,
      },
      {
        text: 'Valor Final', sortable: true, value: 'finalValue', width: 50,
      }, */
    ])
    const url = ref(`${HTTP.defaults.baseURL}identity-api/SystemLogs`)
    const cargando = ref(false)
    const search = ref({ ObjectId: notificationId })
    const havePagination = ref(true)
    const haveSearch = ref(false)
    const haveDetail = ref(true)
    const dialog_log = ref(false)
    const id_log = ref('')
    const close_dialog = () => {
      dialog_log.value = false
    }
    const detailItem = item => {
      id_log.value = item.systemLogId
      dialog_log.value = true

      // router.push(
      //   {
      //     name: 'details_log_sistema',
      //     params: {
      //       id: item.systemLogId,
      //       formTitulo,
      //       cancelarForm: 'notificaciones',
      //       items,
      //     },
      //   },
      // )
    }

    return {
      menu: ref(false),
      formTitulo,
      tab,
      tabs,
      keyComponnet,
      loadingSaveForm,
      image,
      imageFile,
      defaultImage,
      iconoLink,
      fileInput,
      offLink,
      isEditing,
      obj,
      items,
      campos,
      form,
      formdisabled,
      errorMsgTitle,
      errorMsgImage,
      errorMsgShortMessage,
      errorMsgLargeMessage,
      errorMsgLink,
      errorMsgPublicationDate,
      errorMsgLocation,
      errorMsgStatus,
      erroresDialog,
      errorMsgCivilStatus,
      errorMsgAgeRangeMin,
      errorMsgAgeRangeMax,
      errorMsgSector,
      errorMsgnameLink,
      titleDialog,
      dialog,
      notificaciones,
      minDate,
      listaStatus,
      listSector,
      civilStatus,
      ageRangeMin,
      ageRangeMax,
      gender,
      range,
      keyRangeComponnent,
      deshabilitarForm,
      disabledFields,
      dateNow,
      isUrl,
      loadingSector,
      onChangeCivilStatus,
      onBlurCivilStatus,
      onBlurGenero,
      onChangeSector,
      onBlurSector,
      getCantidadDestinatarios,
      changeTodasEdades,
      valueAllAges,
      tooltips,
      onChangeEdadMin,
      onChangeEdadMax,
      refMaxAge,
      refMinAge,

      // Logs del Sistema
      tituloTabla,
      headers,
      url,
      cargando,
      search,
      havePagination,
      haveSearch,
      haveDetail,
      detailItem,
      close_dialog,
      dialog_log,
      id_log,

      // iconos
      icons: {
        mdiCalendar,
        mdiAlertOctagonOutline,
        mdiAlertCircleOutline,
      },
      previewFiles: event => {
        if (event) {
          const reader = new FileReader()
          reader.readAsDataURL(event)
          reader.onload = () => {
            image.value = reader.result
            obj.image = reader.result
          }
        } else {
          image.value = ''
          obj.image = ''
        }
      },

      saveForm: async () => {
        if (form.value.validate()) {
          loadingSaveForm.value = true

          // const listSectorString = obj.sector ? obj.sector.toString() : ''
          let civilStatusTemp = []
          if (obj.civilStatus.length > 0) {
            if (obj.civilStatus.includes('all')) {
              civilStatusTemp = null
            } else {
              civilStatusTemp = obj.civilStatus
            }
          }
          let genderTemp = null
          if (obj.gender !== 'all') {
            genderTemp = obj.gender
          }

          let listSectorTemp = []
          let listSectorString = null
          if (obj.sector.length > 0) {
            for (let index = 0; index < obj.sector.length; index += 1) {
              const element = obj.sector[index]
              if (typeof element === 'object') {
                listSectorTemp = [...listSectorTemp, element.SECT_NOMEN]
              }
              if (typeof element === 'string') {
                listSectorTemp = [...listSectorTemp, element]
              }
            }
          }

          if (listSectorTemp.includes('TODOS')) {
            listSectorString = null
          } else {
            listSectorString = listSectorTemp.length > 0 ? listSectorTemp.toString() : null
          }

          // const objToSave = {
          //   title: obj.title,

          //   // image: obj.image,
          //   shortMessage: obj.shortMessage,
          //   largeMessage: obj.largeMessage,
          //   link: obj.link,
          //   nameLink: obj.nameLink,
          //   publicationDate: obj.publicationDate,
          //   location: obj.location,
          //   status: obj.status,
          //   gender: genderTemp || null,
          //   civilStatus: civilStatusTemp ? civilStatusTemp.toString() : null,

          //   sector: listSectorString,
          //   ageMin: obj.allAges ? null : obj.ageRangeMin,
          //   ageMax: obj.allAges ? null : obj.ageRangeMax,
          //   addressee: obj.addressee,
          //   alertType: 'notification',
          // }

          let objToSave = {}
          if (typeof obj.image === 'string') {
            objToSave = {
              title: obj.title,
              image: obj.image,
              shortMessage: obj.shortMessage,
              largeMessage: obj.largeMessage,
              link: obj.link,
              nameLink: obj.nameLink,
              publicationDate: obj.publicationDate,
              location: obj.location,
              status: obj.status,
              gender: genderTemp,
              civilStatus: civilStatusTemp ? civilStatusTemp.toString() : '',
              sector: listSectorString,
              ageMin: obj.allAges ? null : obj.ageRangeMin,
              ageMax: obj.allAges ? null : obj.ageRangeMax,
              addressee: obj.addressee,
              alertType: 'notification',
            }
          }

          if (typeof obj.image === 'object') {
            objToSave = {
              title: obj.title,
              shortMessage: obj.shortMessage,
              largeMessage: obj.largeMessage,
              link: obj.link,
              nameLink: obj.nameLink,
              publicationDate: obj.publicationDate,
              location: obj.location,
              status: obj.status,
              gender: obj.gender,
              civilStatus: civilStatusTemp ? civilStatusTemp.toString() : '',
              sector: listSectorString,
              ageMin: obj.allAges ? null : obj.ageRangeMin,
              ageMax: obj.allAges ? null : obj.ageRangeMax,
              addressee: obj.addressee,
              alertType: 'notification',
            }
          }
          console.log('Campo Imagen')
          console.log(obj.image)
          console.log(typeof obj.image)

          try {
            const URL = `${HTTP.defaults.baseURL}marketing-api/notifications/${notificationId}`
            const resp = await HTTP.put(URL, objToSave)
            loadingSaveForm.value = false
            if (resp.status === 200) {
              loadingSaveForm.value = false
              if (obj.image && typeof obj.image === 'object') {
                try {
                  loadingSaveForm.value = true
                  const URLImg = `${HTTP.defaults.baseURL}marketing-api/notifications/${notificationId}/images`
                  const formData = new FormData()
                  formData.append('file', obj.image)
                  const imgResp = await HTTP.post(URLImg, formData)
                } catch (error) {
                  console.log(error)
                }
              }
              notificaciones.snackbar = true
              notificaciones.color = 'success'
              notificaciones.text = 'El registro se a actualizado satisfactoriamente'
              loadingSaveForm.value = false
              router.push({
                name: 'notificaciones',
                params: {
                  notificaciones: {
                    snackbar: notificaciones.snackbar,
                    text: notificaciones.text,
                    color: notificaciones.color,
                  },
                },
              })
            }
          } catch (error) {
            const { errors } = error.response.data
            if (errors) {
              let errorDialog = true
              if (errors.Title) {
                for (let index = 0; index < errors.Title.length; index += 1) {
                  campos.title.mensajesError.push(errors.Title[index])
                }
                errorDialog = false
                campos.title.error = true
              }
              if (errors.Image) {
                for (let index = 0; index < errors.Image.length; index += 1) {
                  campos.image.mensajesError.push(errors.Image[index])
                }
                errorDialog = false
                campos.image.error = true
              }
              if (errors.ShortMessage) {
                for (let index = 0; index < errors.ShortMessage.length; index += 1) {
                  campos.shortMessage.mensajesError.push(errors.ShortMessage[index])
                }
                errorDialog = false
                campos.shortMessage.error = true
              }
              if (errors.LargeMessage) {
                for (let index = 0; index < errors.LargeMessage.length; index += 1) {
                  campos.largeMessage.mensajesError.push(errors.LargeMessage[index])
                }
                errorDialog = false
                campos.largeMessage.error = true
              }
              if (errors.Link) {
                for (let index = 0; index < errors.Link.length; index += 1) {
                  campos.link.mensajesError.push(errors.Link[index])
                }
                errorDialog = false
                campos.link.error = true
              }
              if (errors.PublicationDate) {
                for (let index = 0; index < errors.PublicationDate.length; index += 1) {
                  campos.publicationDate.mensajesError.push(errors.PublicationDate[index])
                }
                errorDialog = false
                campos.publicationDate.error = true
              }
              if (errors.Location) {
                for (let index = 0; index < errors.Location.length; index += 1) {
                  campos.location.mensajesError.push(errors.Location[index])
                }
                errorDialog = false
                campos.location.error = true
              }
              if (errors.civilStatus) {
                for (let index = 0; index < errors.civilStatus.length; index += 1) {
                  campos.civilStatus.mensajesError.push(errors.civilStatus[index])
                }
                errorDialog = false
                campos.civilStatus.error = true
              }
              if (errors.sector) {
                for (let index = 0; index < errors.sector.length; index += 1) {
                  campos.sector.mensajesError.push(errors.sector[index])
                }
                errorDialog = false
                campos.sector.error = true
              }
              if (errors.ageMin) {
                for (let index = 0; index < errors.ageMin.length; index += 1) {
                  campos.ageMin.mensajesError.push(errors.ageMin[index])
                }
                errorDialog = false
                campos.ageMin.error = true
              }
              if (errors.ageMax) {
                for (let index = 0; index < errors.ageMax.length; index += 1) {
                  campos.ageMax.mensajesError.push(errors.ageMax[index])
                }
                errorDialog = false
                campos.ageMax.error = true
              }
              if (errorDialog) {
                let error = ''
                const keysError = Object.keys(errors)
                for (let index = 0; index < keysError.length; index += 1) {
                  for (let index1 = 0; index1 < errors[keysError[index]].length; index1 += 1) {
                    error += errors[keysError[index]][index1]
                  }
                }
                notificaciones.snackbar = true
                notificaciones.color = 'error'
                notificaciones.text = error
              }
            }
            loadingSaveForm.value = false
          }
        }
      },
      onPickFile: () => {
        fileInput.value.click()
      },
      onFilePicked: event => {
        const { files } = event.target
        const filename = files[0] ? files[0].name : ''
        const size = files[0] ? files[0].size : ''
        const ext = files[0] ? files[0].type : ''
        if (files[0]) {
          // si no tiene una extension muestro un error
          if (filename && filename.lastIndexOf('.') <= 0) {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = 'Por favor selecciona un archivo valido'
          } else if (size && size >= 1000000) {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = 'Máximo 1 fichero. Límite de 1 MB'
          } else if (ext && ext !== 'image/jpeg' && ext !== 'image/jpg' && ext !== 'image/png') {
            notificaciones.snackbar = true
            notificaciones.color = 'warning'
            notificaciones.text = `Los archivos de tipo ${ext} no estan permitidos. Tipos permitidos: png jpg jpeg.`
          } else {
            const fileReader = new FileReader()
            if (fileReader) {
              fileReader.addEventListener('load', () => {
                defaultImage.value = fileReader.result
              })
              fileReader.readAsDataURL(files[0])
              obj.image = files[0]
            }
          }
        }
      },
      cancelarForm: () => {
        router.push({ name: 'notificaciones' })
      },
    }
  },
}
</script>
<style scoped>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.7;
  position: absolute;
  width: 100%;
}
</style>
