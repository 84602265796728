import { upperFirst } from 'lodash'

export default {
  name: 'AppLabel',
  functional: true,
  props: {
    field: { type: [String, Object], required: true },
    // eslint-disable-next-line vue/require-prop-types
    required: { default: true },
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  render: (h, { props }) => {
    const label =
      typeof props.field === 'string'
        ? props.field
        : props.field.label || upperFirst(props.field.name)
    return props.required
      ? [label, <span style="color: var(--v-error-base);"> *</span>]
      : [label]
  },
}
